.bebas-neue-bold-header {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: rgb(0, 0, 0);
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .outfit-regular-header {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
  }

  .bebas-neue-bold-label {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 20px;
    color: rgb(0, 0, 0);
    margin-top: 25px;
    margin-bottom: 25px;
  }