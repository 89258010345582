.touch :hover {
    background-color: black;
    color: white;
  }
  
.bebas-neue-regular {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-size: 25px;
    font-style: normal;
    color: rgb(0, 0, 0);
  }
  
  .background {
    background-color: rgb(253, 253, 253);
    color: rgb(255, 0, 0);
  }

  .background.bebas-neue-regular {
    color: white; /* Ensure this overrides other color settings */
  }
  
  .centered {
    font-size: 100px;
    position: absolute;
    top: 70%;
    left: 10%;
  }

  .outfit-regular {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
  }