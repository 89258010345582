body {
  min-width: 320px;
  height: 120vh;
  /* padding: 10px; */
}


.normal {
    background-color: white;
    padding: 0px;
    margin-top: 0px;
}

.opening {
  padding: 0px;
  margin-top: 0px;
  background-color: black;
  
}