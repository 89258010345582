body {
    background-color: black;
    color: black;
} 

.opening-body {
    background-color: black;
}
.bebas-neue-bold-opening {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 80px;
    color: white;
  }

.small-margin{
    margin-top: 50px;
    display: flex; /* or inline-flex */
    justify-content:center;
    align-items: center;
}

.alignment {
    margin-top: 100px;
    display: flex; /* or inline-flex */
    justify-content:center;
    align-items: center;
}

.text-margin{
    margin-left: 50px;
    margin-right: 50px;
}

.outfit-regular {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
  }