.container-size {
    height: 45%;
    width:  100%;
    background-color: rgb(0, 0, 0);
   display: flex; /* or inline-flex */
  justify-content:center;
  align-items: center;
}

.img-sizing {
    height: 555px; 
    
}